import * as React from 'react';
import classNames from 'classnames';

import Layout from '../containers/Layout';
import Seo from '../components/Seo';
import useTranslation from '../hooks/useTranslation';
import Heading from '../components/Heading';
import Content from '../containers/Content';

function MenuChildren({ pageContext: { currentPath, name } }) {
  const { t, translations, locale } = useTranslation();

  return (
    <Layout background="hero" title={t(`SEO.MENU_ITEM.${name}`)}>
      <Seo
        title={t(`SEO.MENU_ITEM.${name}`)}
        location={currentPath}
        lang={locale}
      />

      <Content title={t(`SEO.MENU_ITEM.${name}`)}>
        {translations.MENU_CHILDREN[name]?.CATEGORIES.map((category, index) => (
          <div
            key={index}
            className={classNames('space-y-6', {
              'text-center': name === 'SHISHA',
            })}
          >
            <div className="space-y-2">
              {category.TITLE.KEY && (
                <Heading
                  level="h3"
                  visualLevel={category.TITLE.VALUE ? 'h4' : 'h5'}
                  weight="bold"
                  uppercase
                  className="tracking-wide"
                >
                  {category.TITLE.KEY} {category.TITLE.VALUE}
                </Heading>
              )}

              {category.DISCLAIMER && (
                <p className="italic text-sm">{category.DISCLAIMER}</p>
              )}
            </div>

            {category.ITEMS.map((item, index) => (
              <div key={index} className="space-y-2">
                {item.TITLE && (
                  <Heading level="h4" visualLevel="h6" weight="bold">
                    {item.TITLE}
                  </Heading>
                )}

                <div>
                  {item.CATEGORY.map((category, index) => (
                    <div className="grid grid-cols-4 gap-4" key={index}>
                      <div
                        className={classNames(
                          category.VALUE ? 'col-span-3' : 'col-span-4',
                        )}
                      >
                        <p>{category.TYPE}</p>
                        {category.DESCRIPTION && (
                          <p className="text-sm">{category.DESCRIPTION}</p>
                        )}
                      </div>
                      {category.VALUE && (
                        <p className="col-span-1">{category.VALUE}</p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}

        {translations.MENU_CHILDREN[name]?.DISCLAIMER && (
          <p className="italic text-center">
            {translations.MENU_CHILDREN[name].DISCLAIMER}
          </p>
        )}
      </Content>
    </Layout>
  );
}

export default MenuChildren;
